import React, { useState } from 'react';
import { Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

function QuadrantFeedback() {
    // Feedback issues to display
    const issues = [
        'Call choppiness',
        'Call cannot be established',
        'Partner unable to hear',
        'Unable to hear partner',
        'Call dropped unexpectedly',
    ];

    const [selectedIssue, setSelectedIssue] = useState('');
    const [details, setDetails] = useState('');

    const handleSubmit = () => {
        // Clear the details and issues selection
        setSelectedIssue('');
        setDetails('');
        // Additional logic to handle the submission can be added here
    };

    return (
        <Box p={2}>
            <Typography variant="h6" gutterBottom>
                Feedback
            </Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel id="issue-select-label">Issue</InputLabel>
                <Select
                    labelId="issue-select-label"
                    value={selectedIssue}
                    onChange={(e) => setSelectedIssue(e.target.value)}
                    label="Issue"
                >
                    {issues.map((issue, index) => (
                        <MenuItem key={index} value={issue}>
                            {issue}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Details"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </Box>
    );
}

export default QuadrantFeedback;
