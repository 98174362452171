import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

function QuadrantCallInfo() {
  // Example data for demonstration
  const startTime = '10:00 AM';
  const expectedDuration = '30 minutes';
  const currentCallTime = '10:15 AM';
  const averageLatency = '150ms';
  const issuesDetected = 2;

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Call Information
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Typography variant="body2">
        <strong>Start Time:</strong> {startTime}
      </Typography>
      <Typography variant="body2">
        <strong>Expected Duration:</strong> {expectedDuration}
      </Typography>
      <Typography variant="body2">
        <strong>Current Call Time:</strong> {currentCallTime}
      </Typography>
      <Typography variant="body2">
        <strong>Average Latency:</strong> {averageLatency}
      </Typography>
      <Typography variant="body2">
        <strong>Issues Detected:</strong> {issuesDetected}
      </Typography>
    </Box>
  );
}

export default QuadrantCallInfo;
