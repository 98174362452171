import React from 'react';
import { Box } from '@mui/material';

// Import the quadrant components
import QuadrantRole from './QuadrantRole';
import QuadrantInstructions from './QuadrantInstructions';
import QuadrantCallInfo from './QuadrantCallInfo';
import QuadrantFeedback from './QuadrantFeedback';

function CallQuadrants() {
    return (
        <Box>
            {/* Quadrants */}
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                gridTemplateRows="25% 75%"
                gap={2}
                height="100vh" // Ensure the Box takes the full viewport height
            >
                {/* Top Left */}
                <Box sx={{ overflowY: 'auto' }}>
                    <QuadrantRole />
                </Box>

                {/* Top Right */}
                <Box sx={{ overflowY: 'auto' }}>
                    <QuadrantCallInfo />
                </Box>

                {/* Bottom Left */}
                <Box sx={{ overflowY: 'auto' }}>
                    <QuadrantInstructions allowCheck={true} />
                </Box>

                {/* Bottom Right */}
                <Box sx={{ overflowY: 'auto' }}>
                    <QuadrantFeedback />
                </Box>
            </Box>
        </Box>
    );
}

export default CallQuadrants;
