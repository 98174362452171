import React from 'react';
import { motion } from 'framer-motion';
import Wavify from 'react-wavify';

function Home() {
  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#ffffff",
      }}
    >
      {/* Navbar */}
      <nav
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          backgroundColor: "#ffffff",
          zIndex: 2,
          display: "flex",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <a
          href="/"
          style={{
            textDecoration: "none",
            color: "#5CBEF9",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Coming Soon
        </a>
      </nav>

      {/* Content Area */}
      <div
        style={{
          position: "relative",
          zIndex: 1,
          width: "100%",
          height: "calc(100vh - 60px)", // leave room for navbar
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "60px", // space for navbar
        }}
      >
        <img
          src="/logo.png"
          alt="Bonus Health Logo"
          style={{ width: "40%", marginBottom: "30px", backgroundColor: "#ffffff" }}
        />
        <motion.p
          style={{ fontSize: "1.3rem", color: "#5CBEF9", margin: "0 20px" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          Delivering preventative medicine at <strong>digital scale</strong>
        </motion.p>
      </div>

      {/* Wave Background at Bottom */}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          overflow: "hidden",
          lineHeight: 0,
        }}
      >
        <Wavify
          fill="#5CBEF9"
          paused={false}
          options={{
            height: 50,
            amplitude: 50,
            speed: 0.3,
            points: 3,
          }}
        />
      </div>
    </div>
  );
}

export default Home;
