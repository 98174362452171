// Courses.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Box,
  Alert,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MetricsPopup from './MetricsPopup';
import UserAutocomplete from './UserAutocomplete';
import { API_BASE_URL } from '../constants';


function Courses() {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [lessons, setLessons] = useState([]);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [draggedItems, setDraggedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetUser, setResetUser] = useState(false);
  const [addTimeLimits, setAddTimeLimits] = useState(false);

  const [metrics, setMetrics] = useState([]);
  const [includeMetrics, setIncludeMetrics] = useState({});
  const [requiredMetrics, setRequiredMetrics] = useState({});
  const [showMetricModal, setShowMetricModal] = useState(false);

  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);

  const fetchCourses = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/courses`);
      const data = await response.json();
      if (data.success) {
        setCourses(data.courses || []);
      } else {
        setErrorMessage(data.error || 'Failed to fetch courses.');
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
      setErrorMessage('An error occurred while fetching courses.');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/users`);
      const data = await response.json();
      if (data.success) {
        setUsers(data.users || []);
      } else {
        setErrorMessage(data.error || 'Failed to fetch users.');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setErrorMessage('An error occurred while fetching users.');
    }
  };

  const fetchMetrics = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/metrics`);
      const data = await response.json();
      if (data.success) {
        setMetrics(data.metrics || []);
        const initialIncludeMetrics = {};
        const initialRequiredMetrics = {};
        data.metrics.forEach((metric) => {
          initialIncludeMetrics[metric.id] = true;
          initialRequiredMetrics[metric.id] = false;
        });
        setIncludeMetrics(initialIncludeMetrics);
        setRequiredMetrics(initialRequiredMetrics);
      } else {
        console.error('Failed to fetch metrics:', data.error);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchUsers();
    fetchMetrics();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      const fetchLessons = async () => {
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        try {
          const response = await fetch(
            `${API_BASE_URL}/sget_curricula?program=${selectedCourse.subfolder}&contents=1`
          );
          const data = await response.json();
          if (data.success) {
            setLessons(data.curricula || []);
          } else {
            setLessons([]);
            setErrorMessage(data.error || 'Failed to fetch lessons.');
          }
        } catch (error) {
          console.error('Error fetching lessons:', error);
          setErrorMessage('An error occurred while fetching lessons.');
          setLessons([]);
        } finally {
          setLoading(false);
        }
      };
      fetchLessons();
    } else {
      setLessons([]);
    }
  }, [selectedCourse]);

  const handleCourseChange = (event) => {
    const course = courses.find((c) => c.id === event.target.value);
    setSelectedCourse(course);
    setLessons([]);
    setDraggedItems([]);
  };

  const handleUserChange = (newUserId) => {
    setUserId(newUserId);
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (destination.droppableId === 'selectedList') {
      if (source.droppableId === 'availableList') {
        const lesson = lessons.find((lesson) => lesson.title === draggableId);
        if (lesson && !draggedItems.some((item) => item.title === lesson.title)) {
          setDraggedItems((prev) => [...prev, lesson]);
        }
      } else if (source.droppableId === 'selectedList') {
        const newDraggedItems = Array.from(draggedItems);
        const [removed] = newDraggedItems.splice(source.index, 1);
        newDraggedItems.splice(destination.index, 0, removed);
        setDraggedItems(newDraggedItems);
      }
    }
  };

  const handleRemoveLesson = (index) => {
    const newDraggedItems = [...draggedItems];
    newDraggedItems.splice(index, 1);
    setDraggedItems(newDraggedItems);
  };

  const handleSubmit = () => {
    if (!userId || draggedItems.length === 0) {
      setErrorMessage('Please select a user and at least one lesson.');
      return;
    }

    setShowMetricModal(true);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleMetricModalSubmit = async () => {
    setShowMetricModal(false);

    const paths = draggedItems.map((item) => item.title);

    const payload = {
      user_id: userId,
      paths: paths,
      course_id: selectedCourse.id,
    };

    if (resetUser) {
      payload.reset = true;
    }

    if (addTimeLimits) {
      payload.add_time_limits = true;
    }

    const selectedMetricIds = metrics
      .filter((metric) => includeMetrics[metric.id])
      .map((metric) => metric.id);

    const requiredMetricIds = metrics
      .filter((metric) => requiredMetrics[metric.id])
      .map((metric) => metric.id);

    if (selectedMetricIds.length > 0) {
      payload.metric_ids = selectedMetricIds;
    }

    if (requiredMetricIds.length > 0) {
      payload.required_metric_ids = requiredMetricIds;
    }

    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const response = await fetch(`${API_BASE_URL}/sassign_epic/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Course assigned successfully.');
        setDraggedItems([]);
      } else {
        setErrorMessage(data.error || 'Failed to set up epic.');
      }
    } catch (error) {
      console.error('Error setting up epic:', error);
      setErrorMessage('An error occurred while setting up epic.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Courses
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button variant="contained" color="primary" onClick={() => setShowAddCourseModal(true)}>
          Add Course
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setShowEditCourseModal(true)} sx={{ ml: 2 }}>
          Edit Course
        </Button>
      </Box>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* Course Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Select Course"
              value={selectedCourse.id || ''}
              onChange={handleCourseChange}
              fullWidth
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.course_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* User Dropdown */}
          <Grid item xs={12} sm={6}>
            <UserAutocomplete
              users={users}
              userId={userId}
              onUserChange={handleUserChange}
              label="Select User"
              fullWidth
            />
          </Grid>

          {/* Reset User Checkbox */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={resetUser}
                  onChange={(e) => setResetUser(e.target.checked)}
                  color="primary"
                />
              }
              label="Reset user during this assignment"
            />
          </Grid>

          {/* Add Time Limits Checkbox */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addTimeLimits}
                  onChange={(e) => setAddTimeLimits(e.target.checked)}
                  color="primary"
                />
              }
              label="Add daily time constraints"
            />
          </Grid>
        </Grid>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Available Lessons</Typography>
              <Droppable droppableId="availableList" isDropDisabled={true}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ minHeight: 400, border: '1px solid #ccc', p: 2 }}
                  >
                    {lessons.map((lesson, index) => {
                      const prefixToRemove = 'gs://bonus-main-datalake/curricula';
                      const pathWithoutInstructions = lesson.title
                        .replace('/instructions.json', '')
                        .replace(prefixToRemove, '');
                      return (
                        <Draggable
                          key={lesson.title}
                          draggableId={lesson.title}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{ mb: 1 }}
                            >
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel-content-${index}`}
                                  id={`panel-header-${index}`}
                                >
                                  <Typography variant="subtitle1">
                                    {pathWithoutInstructions}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <strong>Intro:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.intro}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <strong>Prompt:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.prompt}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <strong>Text:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.text}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Selected Lessons</Typography>
              <Droppable droppableId="selectedList">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ minHeight: 400, border: '1px solid #ccc', p: 2 }}
                  >
                    {draggedItems.map((lesson, index) => {
                      const prefixToRemove = 'gs://bonus-main-datalake/curricula';
                      const pathWithoutInstructions = lesson.title
                        .replace('/instructions.json', '')
                        .replace(prefixToRemove, '');
                      return (
                        <Draggable
                          key={lesson.title}
                          draggableId={lesson.title}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              sx={{ mb: 1 }}
                            >
                              <Accordion>
                                <AccordionSummary
                                  {...provided.dragHandleProps}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel-content-selected-${index}`}
                                  id={`panel-header-selected-${index}`}
                                >
                                  <Typography variant="subtitle1">
                                    {pathWithoutInstructions}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <strong>Intro:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.intro}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <strong>Prompt:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.prompt}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <strong>Text:</strong>{' '}
                                    {lesson.instructions && lesson.instructions.text}
                                  </Typography>
                                  {/* Remove Button */}
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleRemoveLesson(index)}
                                    sx={{ mt: 2 }}
                                  >
                                    Remove
                                  </Button>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </DragDropContext>

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit Assignment
          </Button>
        </Box>
      </Box>

      {/* Metrics Popup */}
      <MetricsPopup
        open={showMetricModal}
        onClose={() => setShowMetricModal(false)}
        metrics={metrics}
        includeMetrics={includeMetrics}
        setIncludeMetrics={setIncludeMetrics}
        requiredMetrics={requiredMetrics}
        setRequiredMetrics={setRequiredMetrics}
        onSubmit={handleMetricModalSubmit}
      />

      {/* Add Course Popup */}
      <AddCoursePopup
        open={showAddCourseModal}
        onClose={() => setShowAddCourseModal(false)}
        onSubmit={() => {
          fetchCourses();
        }}
      />

      {/* Edit Course Popup */}
      <EditCoursePopup
        open={showEditCourseModal}
        onClose={() => setShowEditCourseModal(false)}
        onSubmit={() => {
          fetchCourses();
        }}
        courses={courses}
      />
    </Container>
  );
}

function AddCoursePopup({ open, onClose, onSubmit }) {
  // State variables for form fields
  const [agentName, setAgentName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const [entityName, setEntityName] = useState('');
  const [subfolder, setSubfolder] = useState('');
  // New fields
  const [emailReplyPrompt, setEmailReplyPrompt] = useState('');
  const [smsReplyPrompt, setSmsReplyPrompt] = useState('');
  // Prompt fields
  const [prompt, setPrompt] = useState('');
  const [endCallPrompt, setEndCallPrompt] = useState('');
  const [tooEarlyPrompt, setTooEarlyPrompt] = useState('');
  const [tooLatePrompt, setTooLatePrompt] = useState('');
  const [tooEarlyIntro, setTooEarlyIntro] = useState('');
  const [tooLateIntro, setTooLateIntro] = useState('');
  const [courseStartMessage, setCourseStartMessage] = useState('');
  const [courseEndMessage, setCourseEndMessage] = useState('');
  const [missedCallText, setMissedCallText] = useState('');
  const [missedSessionText, setMissedSessionText] = useState('');

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async () => {
    // Build payload
    const payload = {
      agent_name: agentName,
      course_name: courseName,
      email_contact: emailContact,
      entity_name: entityName,
      subfolder: subfolder,
      prompt: prompt,
      end_call_prompt: endCallPrompt,
      too_early_prompt: tooEarlyPrompt,
      too_late_prompt: tooLatePrompt,
      too_early_intro: tooEarlyIntro,
      too_late_intro: tooLateIntro,
      course_start_message: courseStartMessage,
      course_end_message: courseEndMessage,
      missed_call_text: missedCallText,
      missed_session_text: missedSessionText,
      email_reply_prompt: emailReplyPrompt,
      sms_reply_prompt: smsReplyPrompt,
    };

    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/sadd_course`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Course added successfully.');
        // Refresh courses list
        onSubmit();
        // Close the dialog
        onClose();
      } else {
        setErrorMessage(data.error || 'Failed to add course.');
      }
    } catch (error) {
      console.error('Error adding course:', error);
      setErrorMessage('An error occurred while adding the course.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Course</DialogTitle>
      <DialogContent>
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <Grid container spacing={2}>
          {/* Agent Name */}
          <Grid item xs={12}>
            <TextField
              label="Agent Name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Course Name */}
          <Grid item xs={12}>
            <TextField
              label="Course Name"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Email Contact */}
          <Grid item xs={12}>
            <TextField
              label="Email Contact"
              value={emailContact}
              onChange={(e) => setEmailContact(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Entity Name */}
          <Grid item xs={12}>
            <TextField
              label="Entity Name"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Subfolder */}
          <Grid item xs={12}>
            <TextField
              label="Subfolder"
              value={subfolder}
              onChange={(e) => setSubfolder(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Email Reply Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Email Reply Prompt"
              value={emailReplyPrompt}
              onChange={(e) => setEmailReplyPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* SMS Reply Prompt */}
          <Grid item xs={12}>
            <TextField
              label="SMS Reply Prompt"
              value={smsReplyPrompt}
              onChange={(e) => setSmsReplyPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* End Call Prompt */}
          <Grid item xs={12}>
            <TextField
              label="End Call Prompt"
              value={endCallPrompt}
              onChange={(e) => setEndCallPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Early Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Too Early Prompt"
              value={tooEarlyPrompt}
              onChange={(e) => setTooEarlyPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Late Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Too Late Prompt"
              value={tooLatePrompt}
              onChange={(e) => setTooLatePrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Early Intro */}
          <Grid item xs={12}>
            <TextField
              label="Too Early Intro"
              value={tooEarlyIntro}
              onChange={(e) => setTooEarlyIntro(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Late Intro */}
          <Grid item xs={12}>
            <TextField
              label="Too Late Intro"
              value={tooLateIntro}
              onChange={(e) => setTooLateIntro(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Course Start Message */}
          <Grid item xs={12}>
            <TextField
              label="Course Start Message"
              value={courseStartMessage}
              onChange={(e) => setCourseStartMessage(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Course End Message */}
          <Grid item xs={12}>
            <TextField
              label="Course End Message"
              value={courseEndMessage}
              onChange={(e) => setCourseEndMessage(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Missed Call Text */}
          <Grid item xs={12}>
            <TextField
              label="Missed Call Text"
              value={missedCallText}
              onChange={(e) => setMissedCallText(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Missed Session Text */}
          <Grid item xs={12}>
            <TextField
              label="Missed Session Text"
              value={missedSessionText}
              onChange={(e) => setMissedSessionText(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={24} />}
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary" variant="contained" disabled={loading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EditCoursePopup({ open, onClose, onSubmit, courses }) {
  const [selectedCourseId, setSelectedCourseId] = useState('');

  const [agentName, setAgentName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const [entityName, setEntityName] = useState('');
  const [subfolder, setSubfolder] = useState('');
  const [emailReplyPrompt, setEmailReplyPrompt] = useState('');
  const [smsReplyPrompt, setSmsReplyPrompt] = useState('');
  const [prompt, setPrompt] = useState('');
  const [endCallPrompt, setEndCallPrompt] = useState('');
  const [tooEarlyPrompt, setTooEarlyPrompt] = useState('');
  const [tooLatePrompt, setTooLatePrompt] = useState('');
  const [tooEarlyIntro, setTooEarlyIntro] = useState('');
  const [tooLateIntro, setTooLateIntro] = useState('');
  const [courseStartMessage, setCourseStartMessage] = useState('');
  const [courseEndMessage, setCourseEndMessage] = useState('');
  const [missedCallText, setMissedCallText] = useState('');
  const [missedSessionText, setMissedSessionText] = useState('');

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (selectedCourseId) {
      const selectedCourse = courses.find((course) => course.id === selectedCourseId);
      if (selectedCourse) {
        setAgentName(selectedCourse.agent_name || '');
        setCourseName(selectedCourse.course_name || '');
        setEmailContact(selectedCourse.email_contact || '');
        setEntityName(selectedCourse.entity_name || '');
        setSubfolder(selectedCourse.subfolder || '');

        // Parse prompt_json
        let promptData = {};
        try {
          promptData = JSON.parse(selectedCourse.prompt_json || '{}');
        } catch (e) {
          console.error('Error parsing prompt_json:', e);
        }

        setPrompt(promptData.prompt || '');
        setEndCallPrompt(promptData.end_call_prompt || '');
        setTooEarlyPrompt(promptData.too_early_prompt || '');
        setTooLatePrompt(promptData.too_late_prompt || '');
        setTooEarlyIntro(promptData.too_early_intro || '');
        setTooLateIntro(promptData.too_late_intro || '');
        setCourseStartMessage(promptData.course_start_message || '');
        setCourseEndMessage(promptData.course_end_message || '');
        setMissedCallText(promptData.missed_call_text || '');
        setMissedSessionText(promptData.missed_session_text || '');
        setEmailReplyPrompt(promptData.email_reply_prompt || '');
        setSmsReplyPrompt(promptData.sms_reply_prompt || '');
      }
    } else {
      setAgentName('');
      setCourseName('');
      setEmailContact('');
      setEntityName('');
      setSubfolder('');
      setPrompt('');
      setEndCallPrompt('');
      setTooEarlyPrompt('');
      setTooLatePrompt('');
      setTooEarlyIntro('');
      setTooLateIntro('');
      setCourseStartMessage('');
      setCourseEndMessage('');
      setMissedCallText('');
      setMissedSessionText('');
      setEmailReplyPrompt('');
      setSmsReplyPrompt('');
    }
  }, [selectedCourseId, courses]);

  const handleFormSubmit = async () => {
    const payload = {
      id: selectedCourseId,
      agent_name: agentName,
      course_name: courseName,
      email_contact: emailContact,
      entity_name: entityName,
      subfolder: subfolder,
      prompt: prompt,
      end_call_prompt: endCallPrompt,
      too_early_prompt: tooEarlyPrompt,
      too_late_prompt: tooLatePrompt,
      too_early_intro: tooEarlyIntro,
      too_late_intro: tooLateIntro,
      course_start_message: courseStartMessage,
      course_end_message: courseEndMessage,
      missed_call_text: missedCallText,
      missed_session_text: missedSessionText,
      email_reply_prompt: emailReplyPrompt,
      sms_reply_prompt: smsReplyPrompt,
    };

    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/sedit_course`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Course edited successfully.');
        onSubmit();
        onClose();
      } else {
        setErrorMessage(data.error || 'Failed to edit course.');
      }
    } catch (error) {
      console.error('Error editing course:', error);
      setErrorMessage('An error occurred while editing the course.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Course</DialogTitle>
      <DialogContent>
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <Grid container spacing={2}>
          {/* Course Selection */}
          <Grid item xs={12}>
            <Autocomplete
              options={courses}
              getOptionLabel={(option) => option.course_name || ''}
              renderInput={(params) => <TextField {...params} label="Select Course" />}
              value={courses.find((course) => course.id === selectedCourseId) || null}
              onChange={(event, newValue) => {
                setSelectedCourseId(newValue ? newValue.id : '');
              }}
              fullWidth
            />
          </Grid>

          {/* Agent Name */}
          <Grid item xs={12}>
            <TextField
              label="Agent Name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Course Name */}
          <Grid item xs={12}>
            <TextField
              label="Course Name"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Email Contact */}
          <Grid item xs={12}>
            <TextField
              label="Email Contact"
              value={emailContact}
              onChange={(e) => setEmailContact(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Entity Name */}
          <Grid item xs={12}>
            <TextField
              label="Entity Name"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Subfolder */}
          <Grid item xs={12}>
            <TextField
              label="Subfolder"
              value={subfolder}
              onChange={(e) => setSubfolder(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* Email Reply Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Email Reply Prompt"
              value={emailReplyPrompt}
              onChange={(e) => setEmailReplyPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* SMS Reply Prompt */}
          <Grid item xs={12}>
            <TextField
              label="SMS Reply Prompt"
              value={smsReplyPrompt}
              onChange={(e) => setSmsReplyPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* End Call Prompt */}
          <Grid item xs={12}>
            <TextField
              label="End Call Prompt"
              value={endCallPrompt}
              onChange={(e) => setEndCallPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Early Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Too Early Prompt"
              value={tooEarlyPrompt}
              onChange={(e) => setTooEarlyPrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Late Prompt */}
          <Grid item xs={12}>
            <TextField
              label="Too Late Prompt"
              value={tooLatePrompt}
              onChange={(e) => setTooLatePrompt(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Early Intro */}
          <Grid item xs={12}>
            <TextField
              label="Too Early Intro"
              value={tooEarlyIntro}
              onChange={(e) => setTooEarlyIntro(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Too Late Intro */}
          <Grid item xs={12}>
            <TextField
              label="Too Late Intro"
              value={tooLateIntro}
              onChange={(e) => setTooLateIntro(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Course Start Message */}
          <Grid item xs={12}>
            <TextField
              label="Course Start Message"
              value={courseStartMessage}
              onChange={(e) => setCourseStartMessage(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Course End Message */}
          <Grid item xs={12}>
            <TextField
              label="Course End Message"
              value={courseEndMessage}
              onChange={(e) => setCourseEndMessage(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Missed Call Text */}
          <Grid item xs={12}>
            <TextField
              label="Missed Call Text"
              value={missedCallText}
              onChange={(e) => setMissedCallText(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          {/* Missed Session Text */}
          <Grid item xs={12}>
            <TextField
              label="Missed Session Text"
              value={missedSessionText}
              onChange={(e) => setMissedSessionText(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={24} />}
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary" variant="contained" disabled={loading || !selectedCourseId}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Courses;
