import React from 'react';
import { Box, Typography } from '@mui/material';

function QuadrantRole() {
  // In a real-world scenario, role type and description might be passed in as props or via state.
  const roleType = 'Bonus Therapist (Miami-Dade County)';
  const roleDescription = 'You are a 30 year old dietician based in Dade County. You have done this role for 5 years. Your style is empathetic and you never get annoyed at your patients.';

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Role Name
      </Typography>
      <Typography variant="body1">{roleType}</Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Role Description
      </Typography>
      <Typography variant="body1">{roleDescription}</Typography>
    </Box>
  );
}

export default QuadrantRole;
