import React from 'react';
import { Box } from '@mui/material';
import QuadrantRole from './QuadrantRole';
import CallSummary from './CallSummary';
import CallTranscript from './CallTranscript';
import QuadrantInstructions from './QuadrantInstructions';

function CallView() {
  return (
    <Box p={2} sx={{ height: '100vh', boxSizing: 'border-box' }}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        sx={{ height: '100%' }}
      >
        {/* Left column */}
        <Box sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
          <CallTranscript />
        </Box>

        {/* Right column - transcript */}
        <Box display="flex" flexDirection="column" sx={{ height: '100%', overflow: 'hidden' }}>
          <Box sx={{ height: '35%', overflowY: 'auto' }}>
            <CallSummary />
          </Box>
          <Box sx={{ height: '25%', overflowY: 'auto' }}>
            <QuadrantRole />
          </Box>
          <Box sx={{ height: '40%', overflowY: 'auto' }}>
            <QuadrantInstructions allowCheck={false} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CallView;
