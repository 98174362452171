import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

function CallSummary() {
  // Example data
  const audioSrc = '/call_audio.wav';
  const participants = ['Therapist: Sam Pacman', 'Patient: Karen Cole'];
  const startTime = '10:00 AM';
  const duration = '00:05:26';
  const averageLatency = '610ms';

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Call Summary
        </Typography>

        {/* Simple audio player */}
        <Box sx={{ mb: 2 }}>
          <audio controls style={{ width: '100%' }}>
            <source src={audioSrc} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </Box>

        {/* Participants info */}
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle2"><strong>Participants:</strong></Typography>
          {participants.map((p, idx) => (
            <Typography variant="body2" key={idx}>
              • {p}
            </Typography>
          ))}
        </Box>

        {/* Call time, duration, latency */}
        <Box sx={{ mb: 1 }}>
          <Typography variant="body2">
            <strong>Start Time:</strong> {startTime}
          </Typography>
          <Typography variant="body2">
            <strong>Duration:</strong> {duration}
          </Typography>
          <Typography variant="body2">
            <strong>Average Latency:</strong> {averageLatency}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CallSummary;
