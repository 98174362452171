import React, { useState } from 'react';
import {
  Typography,
  Button,
  Select,
  MenuItem,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import QuadrantRole from './QuadrantRole';
import QuadrantInstructions from './QuadrantInstructions';

function getMondayIndex(d) {
  let day = d.getUTCDay();
  if (day === 0) day = 7;
  return day - 1;
}

function isSameUtcDate(d1, d2) {
  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth() &&
    d1.getUTCDate() === d2.getUTCDate()
  );
}

function makeUtcDate(year, month, day) {
  return new Date(Date.UTC(year, month, day));
}

function generateMonthDaysUtc(year, month) {
  const firstOfMonth = makeUtcDate(year, month, 1);
  const dayOfWeek = getMondayIndex(firstOfMonth);
  const dayOne = 1 - dayOfWeek;
  const daysInMonth = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
  const totalCells = 42;
  const allDays = [];

  for (let i = 0; i < totalCells; i++) {
    const cellDay = dayOne + i;
    const cellDate = makeUtcDate(year, month, cellDay);
    const isCurrentMonth = cellDay >= 1 && cellDay <= daysInMonth;
    allDays.push({ date: cellDate, isCurrentMonth });
  }
  return allDays;
}

function ScheduleView() {
  const today = new Date();
  const [timezone, setTimezone] = useState('America/New_York');
  const [currentYear, setCurrentYear] = useState(today.getUTCFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getUTCMonth());
  // Generate 24 events (9am-5pm) with two 1-hour gaps on weekdays for 12 weeks starting Mar 18, 2025
  const generateWeekdaySchedule = () => {
    const totalWeeks = 12;
    const allEvents = [];
    let idCounter = 1000;

    // Helper to create the 24 daily events
    const createDayEvents = (baseDate) => {
      // Blocks: 9-12, 13-16 (each hour = 4 events x 10 min + 4 breaks x 5 min)
      const blocks = [
      { startHour: 9, endHour: 12 },
      { startHour: 13, endHour: 16 },
      ];
      const dayEvents = [];
      blocks.forEach((block) => {
      for (let hour = block.startHour; hour < block.endHour; hour++) {
        for (let i = 0; i < 4; i++) {
        const eventStart = new Date(baseDate);
        eventStart.setHours(hour, i * 15, 0, 0); // 10-min event + 5-min break
        const eventStartUtc = new Date(eventStart.toLocaleString('en-US', { timeZone: 'America/New_York' }));
        dayEvents.push({
          id: idCounter++,
          lessonNumber: Math.floor(Math.random() * 22) + 1,
          lessonTitle: 'Scheduled Event',
          partnerName: 'John Doe',
          roleType: 'Therapist',
          startUtc: eventStartUtc.toISOString(),
          thumbnail: '',
        });
        }
      }
      });
      return dayEvents;
    };

    // March is 2 in 0-based month, so Date.UTC(2025, 2, 18) => March 18, 2025
    let currentDate = new Date(Date.UTC(2025, 2, 18, 9, 0, 0));

    // Generate for 12 weeks, skip weekends
    for (let d = 0; d < totalWeeks * 7; d++) {
      const dayOfWeek = currentDate.getUTCDay(); // Sun=0, Sat=6
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        allEvents.push(...createDayEvents(currentDate));
      }
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    return allEvents;
  };

  const [schedule, setSchedule] = useState(generateWeekdaySchedule());
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [showCancelBanner, setShowCancelBanner] = useState(false);
  const [cancelBannerMessage, setCancelBannerMessage] = useState('');
  const handleCloseBanner = () => {
    setShowCancelBanner(false);
    setCancelBannerMessage('');
  };
  const handleRequestCancel = (id) => {
    setSchedule((prev) => prev.filter((s) => s.id !== id));
    setCancelBannerMessage('Cancellation request is being processed...');
    setShowCancelBanner(true);
  };

  const handleOpenDialog = (item) => {
    setDialogData(item);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
  };

  // View mode state
  const [viewMode, setViewMode] = useState('month'); // 'month' or 'day'
  const [selectedDay, setSelectedDay] = useState(null);

  // Select changes
  const handleTimezoneChange = (e) => setTimezone(e.target.value);

  // Month nav
  const handlePrevMonth = () => {
    let newMonth = currentMonth - 1;
    let newYear = currentYear;
    if (newMonth < 0) {
      newMonth = 11;
      newYear--;
    }
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };
  const handleNextMonth = () => {
    let newMonth = currentMonth + 1;
    let newYear = currentYear;
    if (newMonth > 11) {
      newMonth = 0;
      newYear++;
    }
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  // Calendar
  const calendarDays = generateMonthDaysUtc(currentYear, currentMonth);
  const rows = [];
  for (let i = 0; i < 6; i++) {
    rows.push(calendarDays.slice(i * 7, i * 7 + 7));
  }
  const monthName = new Date(Date.UTC(currentYear, currentMonth + 1)).toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  const AgendaCard = ({ item }) => {
    const localTime = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(item.startUtc));
    const imgSrc = item.thumbnail || '/thumbnail_placeholder.png';
    return (
      <Card sx={{ mb: 1, border: '1px solid #ccc' }}>
        <CardContent sx={{ p: 1 }}>
          <Typography variant="subtitle2" gutterBottom noWrap>
            Lesson {item.lessonNumber}: {item.lessonTitle}
          </Typography>
          <Typography variant="caption" display="block">
            <strong>Partner:</strong> {item.partnerName}
          </Typography>
          <Typography variant="caption" display="block">
            <strong>Role:</strong> {item.roleType}
          </Typography>
          <Typography variant="caption" display="block">
            <strong>Time:</strong> {localTime}
          </Typography>
        </CardContent>
        <CardActions sx={{ p: 1 }}>
          <Button variant="contained" size="small" onClick={() => handleOpenDialog(item)}>
            View
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => handleRequestCancel(item.id)}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
    );
  };

  // Switch to day view
  const handleDayClick = (dayDate) => {
    setSelectedDay(dayDate);
    setViewMode('day');
  };

  // Daily agenda
  const dailyItems = schedule.filter((evt) =>
    selectedDay ? isSameUtcDate(new Date(evt.startUtc), selectedDay) : false
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body1">Timezone:</Typography>
        <Select value={timezone} onChange={handleTimezoneChange} size="small">
          {Intl.supportedValuesOf('timeZone').map((tz) => (
            <MenuItem key={tz} value={tz}>
              {tz}
            </MenuItem>
          ))}
        </Select>
        <Divider orientation="vertical" flexItem />
        {viewMode === 'month' && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton onClick={handlePrevMonth}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ minWidth: 110, textAlign: 'center' }}>
              {monthName}
            </Typography>
            <IconButton onClick={handleNextMonth}>
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        )}
        {viewMode === 'day' && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button variant="outlined" onClick={() => setViewMode('month')}>
              Back to Month
            </Button>
            <Typography variant="h6">
              {selectedDay &&
                selectedDay.toLocaleDateString('en-US', { timeZone: 'UTC', dateStyle: 'full' })}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />

      {viewMode === 'month' && (
        <>
          <Box sx={{ px: 2, py: 1 }}>
            <Grid container columns={7} spacing={1}>
              {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((dow) => (
                <Grid item xs={1} key={dow}>
                  <Typography variant="subtitle1" align="center">
                    {dow}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto', px: 2, pb: 2 }}>
            {rows.map((week, wIndex) => (
              <Grid container columns={7} spacing={1} key={`week-${wIndex}`} sx={{ mb: 1 }}>
                {week.map((dayObj, dIndex) => {
                  const dayDate = dayObj.date;
                  const isCurrentMonth = dayObj.isCurrentMonth;
                  const dayItems = schedule.filter((evt) =>
                    isSameUtcDate(new Date(evt.startUtc), dayDate)
                  );
                  return (
                    <Grid item xs={1} key={`day-${wIndex}-${dIndex}`}>
                      <Box
                        sx={{
                          minHeight: 100,
                          border: '1px solid #ddd',
                          borderRadius: 1,
                          backgroundColor: isCurrentMonth ? '#fff' : '#f5f5f5',
                          p: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleDayClick(dayDate)}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ color: isCurrentMonth ? 'inherit' : 'text.disabled' }}
                        >
                          {dayDate.getUTCDate()}
                        </Typography>
                        {!!dayItems.length && (
                          <Typography variant="caption" color="primary">
                            {dayItems.length} event(s)
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ))}
          </Box>
        </>
      )}

      {viewMode === 'day' && (
        <Box sx={{ flexGrow: 1, overflow: 'auto', px: 2, py: 2 }}>
          {dailyItems.map((item) => (
            <AgendaCard key={item.id} item={item} />
          ))}
        </Box>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        {dialogData && (
          <>
            <DialogTitle>
              Lesson {dialogData.lessonNumber}: {dialogData.lessonTitle}
            </DialogTitle>
            <DialogContent dividers>
              <Box mb={2}>
                <QuadrantRole allowCheck={false} />
              </Box>
              <Box>
                <QuadrantInstructions allowCheck={false} />
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>

      <Snackbar
        open={showCancelBanner}
        autoHideDuration={3000}
        onClose={handleCloseBanner}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="info" onClose={handleCloseBanner}>
          {cancelBannerMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ScheduleView;
