import React, { useState, useEffect } from 'react';
import {
    Typography, TextField, Button, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody,
    IconButton, Box, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AdminAddUser from './AdminAddUser';

function ManagementPage() {
    // ROLES
    const [roles, setRoles] = useState([]);
    const [roleSearch, setRoleSearch] = useState('');
    const [editingRoleId, setEditingRoleId] = useState(null);
    const [tempRole, setTempRole] = useState({ title: '', description: '', type: '' });
    const [openAddRole, setOpenAddRole] = useState(false);

    useEffect(() => {
        setRoles([
            { id: 1, title: 'Therapist', description: 'Handles therapy sessions', type: 'therapist' },
            { id: 2, title: 'Patient', description: 'Receives therapy sessions', type: 'patient' }
        ]);
    }, []);

    const filteredRoles = roles.filter((role) => {
        const searchVal = roleSearch.toLowerCase();
        return (
            role.title.toLowerCase().includes(searchVal) ||
            role.description.toLowerCase().includes(searchVal) ||
            role.type.toLowerCase().includes(searchVal)
        );
    });

    const handleAddRole = () => {
        setOpenAddRole(true);
    };

    const handleSaveNewRole = () => {
        const newRole = { ...tempRole, id: roles.length + 1 };
        setRoles([...roles, newRole]);
        setTempRole({ title: '', description: '', type: '' });
        setOpenAddRole(false);
    };

    const handleEditRole = (role) => {
        setEditingRoleId(role.id);
        setTempRole({ title: role.title, description: role.description, type: role.type });
    };

    const handleSaveRole = (id) => {
        setRoles(roles.map(r => (r.id === id ? { ...r, ...tempRole } : r)));
        setEditingRoleId(null);
    };

    const handleDeleteRole = (roleId) => {
        alert(`Delete Role ID: ${roleId} (API call placeholder)`);
    };

    // USERS
    const [users, setUsers] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [editingUserId, setEditingUserId] = useState(null);
    const [tempUser, setTempUser] = useState({});
    const [openAddUser, setOpenAddUser] = useState(false);

    useEffect(() => {
        setUsers([
            { id: 101, firstName: 'John', lastName: 'Doe', email: 'john@example.com', timezone: 'UTC-5', phoneNumber: '123456789', type: 'data labeler' },
            { id: 102, firstName: 'Alice', lastName: 'Smith', email: 'alice@example.com', timezone: 'UTC+1', phoneNumber: '987654321', type: 'admin' },
        ]);
    }, []);

    const filteredUsers = users.filter((user) => {
        const searchVal = userSearch.toLowerCase();
        return (
            user.firstName.toLowerCase().includes(searchVal) ||
            user.lastName.toLowerCase().includes(searchVal) ||
            user.email.toLowerCase().includes(searchVal) ||
            user.type.toLowerCase().includes(searchVal)
        );
    });

    const handleAddUser = () => {
        setOpenAddUser(true);
    };

    const handleEditUser = (user) => {
        if (user.type === 'admin') {
            alert('Cannot edit admin users.');
            return;
        }
        setEditingUserId(user.id);
        setTempUser({ ...user });
    };

    const handleSaveUser = (id) => {
        setUsers(users.map(u => (u.id === id ? { ...u, ...tempUser } : u)));
        setEditingUserId(null);
    };

    const handleDeleteUser = (userId, userType) => {
        if (userType === 'admin') {
            alert('Cannot delete admin users.');
            return;
        }
        alert(`Delete User ID: ${userId} (API call placeholder)`);
    };

    // RESCHEDULING REQUESTS (editing disabled)
    const [rescheduleRequests, setRescheduleRequests] = useState([]);

    useEffect(() => {
        setRescheduleRequests([
            {
                id: 1,
                scheduleId: 'SCH-001',
                requester: 'John Doe',
                secondParty: 'Alice Smith',
                callDate: '2023-06-10',
                callTime: '10:00 AM',
                lessonNumber: 5,
            },
            {
                id: 2,
                scheduleId: 'SCH-002',
                requester: 'Bob Brown',
                secondParty: 'Charlie Green',
                callDate: '2023-06-12',
                callTime: '2:00 PM',
                lessonNumber: 3,
            },
        ]);
    }, []);

    const handleApproveRequest = (requestId) => {
        alert(`Approve request ID: ${requestId}`);
    };

    const handleRejectRequest = (requestId) => {
        alert(`Reject request ID: ${requestId}`);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h5" gutterBottom>Management</Typography>

            {/* ROLES SECTION */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Roles</Typography>
                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <TextField
                            label="Search roles"
                            variant="outlined"
                            size="small"
                            value={roleSearch}
                            onChange={(e) => setRoleSearch(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleAddRole}>Add Role</Button>
                    </Box>
                    <Box sx={{ maxHeight: 250, overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRoles.map((role) => (
                                    <TableRow key={role.id}>
                                        <TableCell>
                                            {editingRoleId === role.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempRole.title}
                                                    onChange={(e) =>
                                                        setTempRole({ ...tempRole, title: e.target.value })
                                                    }
                                                />
                                            ) : (role.title)}
                                        </TableCell>
                                        <TableCell>
                                            {editingRoleId === role.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempRole.description}
                                                    onChange={(e) =>
                                                        setTempRole({ ...tempRole, description: e.target.value })
                                                    }
                                                />
                                            ) : (role.description)}
                                        </TableCell>
                                        <TableCell>
                                            {editingRoleId === role.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempRole.type}
                                                    onChange={(e) =>
                                                        setTempRole({ ...tempRole, type: e.target.value })
                                                    }
                                                />
                                            ) : (role.type)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {editingRoleId === role.id ? (
                                                <>
                                                    <IconButton onClick={() => handleSaveRole(role.id)}>
                                                        <CheckIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton onClick={() => setEditingRoleId(null)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <>
                                                    <IconButton onClick={() => handleEditRole(role)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDeleteRole(role.id)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>

            {/* USERS SECTION */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Users</Typography>
                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <TextField
                            label="Search users"
                            variant="outlined"
                            size="small"
                            value={userSearch}
                            onChange={(e) => setUserSearch(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleAddUser}>Add User</Button>
                    </Box>
                    <Box sx={{ maxHeight: 250, overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Timezone</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>
                                            {editingUserId === user.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempUser.firstName}
                                                    onChange={(e) =>
                                                        setTempUser({ ...tempUser, firstName: e.target.value })
                                                    }
                                                />
                                            ) : (user.firstName)}
                                        </TableCell>
                                        <TableCell>
                                            {editingUserId === user.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempUser.lastName}
                                                    onChange={(e) =>
                                                        setTempUser({ ...tempUser, lastName: e.target.value })
                                                    }
                                                />
                                            ) : (user.lastName)}
                                        </TableCell>
                                        <TableCell>
                                            {editingUserId === user.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempUser.email}
                                                    onChange={(e) =>
                                                        setTempUser({ ...tempUser, email: e.target.value })
                                                    }
                                                />
                                            ) : (user.email)}
                                        </TableCell>
                                        <TableCell>
                                            {editingUserId === user.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempUser.timezone}
                                                    onChange={(e) =>
                                                        setTempUser({ ...tempUser, timezone: e.target.value })
                                                    }
                                                />
                                            ) : (user.timezone)}
                                        </TableCell>
                                        <TableCell>
                                            {editingUserId === user.id ? (
                                                <TextField
                                                    size="small"
                                                    value={tempUser.phoneNumber}
                                                    onChange={(e) =>
                                                        setTempUser({ ...tempUser, phoneNumber: e.target.value })
                                                    }
                                                />
                                            ) : (user.phoneNumber)}
                                        </TableCell>
                                        <TableCell>{user.type}</TableCell>
                                        <TableCell align="right">
                                            {editingUserId === user.id ? (
                                                <>
                                                    <IconButton onClick={() => handleSaveUser(user.id)}>
                                                        <CheckIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton onClick={() => setEditingUserId(null)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <>
                                                    <IconButton onClick={() => handleEditUser(user)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDeleteUser(user.id, user.type)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>

            {/* RESCHEDULING REQUESTS SECTION (no editing) */}
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Rescheduling Requests</Typography>
                    <Box sx={{ maxHeight: 250, overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Schedule ID</TableCell>
                                    <TableCell>Requester</TableCell>
                                    <TableCell>Second Party</TableCell>
                                    <TableCell>Call Date</TableCell>
                                    <TableCell>Call Time</TableCell>
                                    <TableCell>Lesson #</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rescheduleRequests.map((req) => (
                                    <TableRow key={req.id}>
                                        <TableCell>{req.scheduleId}</TableCell>
                                        <TableCell>{req.requester}</TableCell>
                                        <TableCell>{req.secondParty}</TableCell>
                                        <TableCell>{req.callDate}</TableCell>
                                        <TableCell>{req.callTime}</TableCell>
                                        <TableCell>{req.lessonNumber}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleApproveRequest(req.id)}>
                                                <CheckIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton onClick={() => handleRejectRequest(req.id)}>
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>

            {/* ADD ROLE DIALOG */}
            <Dialog open={openAddRole} onClose={() => setOpenAddRole(false)}>
                <DialogTitle>Add New Role</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Title"
                        fullWidth
                        value={tempRole.title}
                        onChange={(e) => setTempRole({ ...tempRole, title: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        fullWidth
                        value={tempRole.description}
                        onChange={(e) => setTempRole({ ...tempRole, description: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Type"
                        fullWidth
                        value={tempRole.type}
                        onChange={(e) => setTempRole({ ...tempRole, type: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddRole(false)}>Cancel</Button>
                    <Button onClick={handleSaveNewRole} variant="contained">Save</Button>
                </DialogActions>
            </Dialog>

            {/* ADD USER DIALOG (popup) */}
            <Dialog open={openAddUser} onClose={() => setOpenAddUser(false)}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <AdminAddUser />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddUser(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ManagementPage;
