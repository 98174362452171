import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

function CallTranscript() {
  const [transcriptData, setTranscriptData] = useState([]);

  useEffect(() => {
    const fetchTranscript = async () => {
      const response = await fetch('/transcript.json');
      const jsonData = await response.json();
      const srtData = jsonData.transcript.map((entry, index) => {
        const startTime = entry.words[0].start;
        const endTime = entry.words[entry.words.length - 1].end;
        const startFormatted = new Date(startTime * 1000).toISOString().substring(11, 23).replace('.', ',');
        const endFormatted = new Date(endTime * 1000).toISOString().substring(11, 23).replace('.', ',');
        return `${index + 1}
${startFormatted} --> ${endFormatted}
[${entry.role}] ${entry.words.map((w) => w.word).join(' ')}`;
      }).join('\n\n');
      setTranscriptData(srtData);
    };

    fetchTranscript();
  }, []);

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent
        sx={{
          flex: '1 1 auto',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 100px)'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Call Transcript
        </Typography>
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          <Typography variant="body2">{transcriptData}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CallTranscript;
