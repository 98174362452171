import React from 'react';

function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        fontFamily: "'Arial', sans-serif",
        color: '#333',
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.png`}
        alt="Logo"
        style={{ width: '150px', marginBottom: '20px' }}
      />
      <h1 style={{ fontSize: '2.5rem', marginBottom: '10px' }}>404 - Page Not Found</h1>
      <p style={{ fontSize: '1.2rem', maxWidth: '500px', marginBottom: '20px' }}>
        Sorry, the page you are looking for does not exist. It might have been moved or deleted.
      </p>
      <a
        href="/"
        style={{
          textDecoration: 'none',
          color: '#007BFF',
          fontSize: '1rem',
          fontWeight: 'bold',
        }}
      >
        Go Back to Home
      </a>
    </div>
  );
}

export default NotFound;
