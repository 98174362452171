/* global firebase */
import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: Make this use users endpoint to parse whitelist from server so it refreshes instantly on new users
const WHITELIST = ['+13476042295', '+13853093127', '+16464672099'];

function SignInPhone() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [statusMessage, setStatusMessage] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize reCAPTCHA (invisible by default)
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });
  }, []);

  const handleSendCode = async () => {
    setStatusMessage('');

    // Validate US phone number
    const usPhoneNumberPattern = /^\+1\d{10}$/;
    if (!usPhoneNumberPattern.test(phoneNumber)) {
      setStatusMessage('Please enter a valid US phone number.');
      return;
    }

    // (Optional) Enforce a whitelist
    if (!WHITELIST.includes(phoneNumber)) {
      setStatusMessage('This phone number is not allowed.');
      return;
    }

    try {
      const appVerifier = window.recaptchaVerifier;
      const result = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
      setConfirmationResult(result);
      setStep(2);
      setStatusMessage('OTP sent! Check your messages.');
    } catch (error) {
      setStatusMessage(error.message);
    }
  };

  const handleVerifyCode = async () => {
    setStatusMessage('');
    if (!confirmationResult) {
      setStatusMessage('No confirmation result. Please send code again.');
      return;
    }

    try {
      await confirmationResult.confirm(otp);

      // On success, redirect back to the page we came from or default to '/'
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (error) {
      setStatusMessage(error.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '75vh',
        p: 2,
      }}
    >
      <img src="/logo.png" alt="Logo" style={{ marginBottom: '20px' }} />
      <Typography variant="h5" gutterBottom>
        Data Portal Phone Sign-In
      </Typography>

      {/* reCAPTCHA container */}
      <div id="recaptcha-container"></div>

      {step === 1 && (
        <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
          <TextField
            label="Phone Number (e.g. +1 555-123-4567)"
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" fullWidth onClick={handleSendCode}>
            Send OTP
          </Button>
        </Box>
      )}

      {step === 2 && (
        <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
          <TextField
            label="Enter OTP"
            variant="outlined"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" fullWidth onClick={handleVerifyCode}>
            Verify OTP
          </Button>
        </Box>
      )}

      {statusMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {statusMessage}
        </Typography>
      )}
    </Box>
  );
}

export default SignInPhone;
