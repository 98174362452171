import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
} from '@mui/material';

function useCallStatus(range) {
    const [data, setData] = useState({ completed: 0, ongoing: 0, failed: 0, scheduled: 0 });
    useEffect(() => {
        // Replace with actual API call
        setData({ completed: 80, ongoing: 5, failed: 2, scheduled: 10 });
    }, [range]);
    return data;
}

function useLessonFrequencies(range) {
    const [data, setData] = useState([]);
    useEffect(() => {
        // Replace with actual API call
        setData(
            Array.from({ length: 20 }, (_, i) => ({
                number: i + 1,
                title: `Lesson ${i + 1}`,
                frequency: Math.floor(Math.random() * 10) + 1,
            }))
        );
    }, [range]);
    return data;
}

function useLatencyData(range) {
    const [distribution, setDistribution] = useState([]);
    const [minLatency, setMinLatency] = useState(0);
    const [maxLatency, setMaxLatency] = useState(0);
    const [avgLatency, setAvgLatency] = useState(0);

    useEffect(() => {
        // Replace with actual API call
        const mock = [0.5, 1.2, 2.4, 2.5, 3.0, 4.2, 5.0];
        setDistribution(mock);
        const minVal = Math.min(...mock);
        const maxVal = Math.max(...mock);
        const avgVal = mock.reduce((acc, val) => acc + val, 0) / mock.length;
        setMinLatency(minVal);
        setMaxLatency(maxVal);
        setAvgLatency(parseFloat(avgVal.toFixed(2)));
    }, [range]);

    return { distribution, minLatency, maxLatency, avgLatency };
}

function useOtherData(range) {
    const [roleFrequencies, setRoleFrequencies] = useState([]);
    const [labelerFrequencies, setLabelerFrequencies] = useState([]);
    const [feedbackList, setFeedbackList] = useState([]);
    const [snippetsLabeled, setSnippetsLabeled] = useState(0);
    const [empathyAvg, setEmpathyAvg] = useState(7.5);
    const [accuracyAvg, setAccuracyAvg] = useState(8.2);
    const [engagementAvg, setEngagementAvg] = useState(6.9);
    const [overallAvg, setOverallAvg] = useState(7.8);
    const [topRankPercent, setTopRankPercent] = useState(40);
    const [secondRankPercent, setSecondRankPercent] = useState(35);
    const [thirdRankPercent, setThirdRankPercent] = useState(25);

    useEffect(() => {
        // Replace with actual API calls
        setRoleFrequencies([
            { role: 'Therapist', frequency: 70 },
            { role: 'Patient', frequency: 50 },
        ]);
        setLabelerFrequencies([
            { name: 'John Doe', frequency: 35, hasNegativeFeedback: false },
            { name: 'Alice Smith', frequency: 25, hasNegativeFeedback: true },
            { name: 'Bob Johnson', frequency: 10, hasNegativeFeedback: false },
        ]);
        setFeedbackList([
            {
                time: '2023-10-09 10:01 AM',
                who: 'Alice',
                type: 'Positive',
                comment: 'Great handling of patient concerns.',
            },
            {
                time: '2023-10-09 09:50 AM',
                who: 'Bob',
                type: 'Neutral',
                comment: 'Session started late.',
            },
            {
                time: '2023-10-09 09:30 AM',
                who: 'John',
                type: 'Negative',
                comment: 'Missed important patient detail.',
            },
        ]);
        setSnippetsLabeled(1234);
    }, [range]);

    return {
        roleFrequencies,
        labelerFrequencies,
        feedbackList,
        snippetsLabeled,
        empathyAvg,
        accuracyAvg,
        engagementAvg,
        overallAvg,
        topRankPercent,
        secondRankPercent,
        thirdRankPercent,
    };
}

function DataMetricsDashboard() {
    const [range, setRange] = useState('hour');
    const callStatus = useCallStatus(range);
    const lessonFrequencies = useLessonFrequencies(range);
    const { distribution, minLatency, maxLatency, avgLatency } = useLatencyData(range);
    const {
        roleFrequencies,
        labelerFrequencies,
        feedbackList,
        snippetsLabeled,
        empathyAvg,
        accuracyAvg,
        engagementAvg,
        overallAvg,
        topRankPercent,
        secondRankPercent,
        thirdRankPercent,
    } = useOtherData(range);

    const handleRangeChange = (e) => {
        setRange(e.target.value);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ mb: 2 }}>
                <Select value={range} onChange={handleRangeChange}>
                    <MenuItem value="hour">Past Hour</MenuItem>
                    <MenuItem value="day">Past Day</MenuItem>
                    <MenuItem value="week">Past Week</MenuItem>
                    <MenuItem value="month">Past Month</MenuItem>
                </Select>
            </Box>

            <Typography variant="h5" gutterBottom>
                Metrics Dashboard
            </Typography>

            {/* Call Status */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Call Status
                    </Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Completed</TableCell>
                                <TableCell>Ongoing</TableCell>
                                <TableCell>Failed</TableCell>
                                <TableCell>Scheduled</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{callStatus.completed}</TableCell>
                                <TableCell>{callStatus.ongoing}</TableCell>
                                <TableCell>{callStatus.failed}</TableCell>
                                <TableCell>{callStatus.scheduled}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            {/* Lesson Frequencies */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Lesson Frequency
                    </Typography>
                    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Lesson #</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Frequency</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lessonFrequencies.map((item, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{item.number}</TableCell>
                                        <TableCell>{item.title}</TableCell>
                                        <TableCell>{item.frequency}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>

            {/* Latency Distribution */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Latency Distribution
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Min: {minLatency}s, Max: {maxLatency}s, Avg: {avgLatency}s
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                        {distribution.map((val, idx) => (
                            <Box
                                key={idx}
                                sx={{
                                    width: 20,
                                    height: val * 20,
                                    backgroundColor: 'primary.main',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Typography variant="caption" sx={{ color: '#fff', pb: 0.5 }}>
                                    {val}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </CardContent>
            </Card>

            {/* Role Frequency */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Role Frequency
                    </Typography>
                    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Frequency</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roleFrequencies.map((item, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{item.role}</TableCell>
                                        <TableCell>{item.frequency}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>

            {/* Labeler Frequency */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Labeler Frequency
                    </Typography>
                    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Labeler</TableCell>
                                    <TableCell>Frequency</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {labelerFrequencies.map((item, idx) => (
                                    <TableRow
                                        key={idx}
                                        sx={
                                            item.hasNegativeFeedback
                                                ? { backgroundColor: '#ffebee' }
                                                : {}
                                        }
                                    >
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.frequency}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>

            {/* Feedback List */}
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Feedback History
                    </Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>Who</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Comment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {feedbackList.map((f, i) => (
                                <TableRow key={i}>
                                    <TableCell>{f.time}</TableCell>
                                    <TableCell>{f.who}</TableCell>
                                    <TableCell>{f.type}</TableCell>
                                    <TableCell>{f.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            {/* Snippets at the bottom */}
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Snippets Labeled
                    </Typography>
                    <Typography variant="body1">
                        Total labeled: {snippetsLabeled}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">Average Scores (1 to 10):</Typography>
                        <Typography variant="body2">Empathy: {empathyAvg}</Typography>
                        <Typography variant="body2">Accuracy: {accuracyAvg}</Typography>
                        <Typography variant="body2">Engagement: {engagementAvg}</Typography>
                        <Typography variant="body2">Overall: {overallAvg}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">Ranking Distribution:</Typography>
                        <Typography variant="body2">
                            Top: {topRankPercent}%, Second: {secondRankPercent}%, Third:{' '}
                            {thirdRankPercent}%
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default DataMetricsDashboard;
