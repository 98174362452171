/* global firebase */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import NotFound from './components/NotFound';
import Home from './components/Home';
import AdminDashboard from './components/AdminDashboard';
import ReactGA from 'react-ga4';
import BonusDataPortal from './components/BonusDataPortal';
import SignInPhone from './components/SignInPhone';
import CallView from './components/CallView';

const TRACKING_ID = 'G-2GMBVTXK4K';
ReactGA.initialize(TRACKING_ID);

const RouteTracker = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  return null;
};

function ProtectedRoute({ children }) {
  const location = useLocation();
  const user = firebase.auth().currentUser; // or your own auth check

  if (!user) {
    // If not signed in, go log in
    // and pass { from: location } so we remember where we came from
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  return children; // user is signed in, render protected content
}


function App() {
  return (
    <Router>
      <RouteTracker />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignInPhone />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="*" element={<NotFound />} />

        {/* Protected routes */}
        <Route
          path="/data_portal"
          element={
            <ProtectedRoute>
              <BonusDataPortal />
            </ProtectedRoute>
          }
        />
        <Route path="/calls/*" element={<ProtectedRoute><CallView /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
