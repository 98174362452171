import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Button,
    Slider,
    Alert
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CallTranscript from './CallTranscript';

function DataLabelingPage() {
    const [audioUrl, setAudioUrl] = useState('');
    const [responses, setResponses] = useState([]);
    const [ratings, setRatings] = useState({
        empathy: 5,
        accuracy: 5,
        engagement: 5,
        overall: 5
    });
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        loadNextExample();
    }, []);

    const loadNextExample = () => {
        setAudioUrl('call_audio.wav');
        setResponses([
            { id: 'current', title: 'Current', text: 'Tell me more about what’s causing the stress.' },
            { id: 'alt1', title: 'Alt 1', text: 'Consider a journaling habit to track stressors.' },
            { id: 'alt2', title: 'Alt 2', text: 'Exercise can help alleviate stress significantly.' }
        ]);
        setFeedbackMessage(null);
        setErrorMessage(null);
        setRatings({ empathy: 5, accuracy: 5, engagement: 5, overall: 5 });
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(responses);
        const [moved] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, moved);
        setResponses(newItems);
    };

    const handleSliderChange = (dim) => (event, newValue) => {
        setRatings({ ...ratings, [dim]: newValue });
    };

    const handleSubmit = () => {
        setFeedbackMessage('Submission successful (placeholder)!');
        setErrorMessage(null);
        setTimeout(() => loadNextExample(), 1000);
    };

    return (
        <Box
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                p: 2,
                maxWidth: 1200,
                margin: '0 auto'
            }}
        >
            <Typography variant="h5" gutterBottom>Data Labeling</Typography>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 2
                }}
            >
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Audio</Typography>
                        {audioUrl ? (
                            <audio controls style={{ width: '100%' }}>
                                <source src={audioUrl} type="audio/mp3" />
                                Your browser does not support the audio element.
                            </audio>
                        ) : (
                            <Typography>No audio available</Typography>
                        )}
                        <CallTranscript />
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Rank Responses (Drag and drop)</Typography>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                                        {responses.map((res, index) => (
                                            <Draggable key={res.id} draggableId={res.id} index={index}>
                                                {(dragProvided) => (
                                                    <Card
                                                        sx={{ mb: 1 }}
                                                        ref={dragProvided.innerRef}
                                                        {...dragProvided.draggableProps}
                                                        {...dragProvided.dragHandleProps}
                                                    >
                                                        <CardContent>
                                                            <Typography variant="subtitle1">
                                                                {res.title}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                {res.text}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Top Response: {responses[0]?.title}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
                            {['empathy','accuracy','engagement','overall'].map((dim) => (
                                <Box key={dim} sx={{ width: 140 }}>
                                    <Typography variant="body2" gutterBottom>
                                        {dim.charAt(0).toUpperCase() + dim.slice(1)}: {ratings[dim]}
                                    </Typography>
                                    <Slider
                                        min={1}
                                        max={10}
                                        step={1}
                                        value={ratings[dim]}
                                        onChange={handleSliderChange(dim)}
                                        valueLabelDisplay="auto"
                                    />
                                </Box>
                            ))}
                        </Box>
                        {feedbackMessage && <Alert severity="success" sx={{ mb: 1, mt: 2 }}>{feedbackMessage}</Alert>}
                        {errorMessage && <Alert severity="error" sx={{ mb: 1 }}>{errorMessage}</Alert>}
                        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 1 }}>
                            Next
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default DataLabelingPage;
