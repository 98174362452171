import React, { useState } from 'react';
import { Box, Typography, Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

function QuadrantInstructions({ allowCheck = true }) {
  const [items, setItems] = useState([
    {
      "text": "Begin with a friendly greeting: “Good morning!”, and congratulate the user for being only 2 lessons away from completing their first week on the program! Tell the user Dr. Bill would be pleased with their progress.",
      "checked": false
    },
    {
      "text": "Confirm the user was reminded about the dangers of sugary snacks and sodas when they last spoke, and ask them if they were able to integrate any healthy snacks into their day yesterday?",
      "checked": false
    },
    {
      "text": "After the user reacts, say something positive about the user's current daily task: weighing themselves. Then, ask the user if they're able to get to their scale?",
      "checked": false
    },
    {
      "text": "Wait for the user to confirm that they can get to their scale, then ask for their weight.",
      "checked": false
    },
    {
      "text": "React to the user's weight by confirming you've recorded it, and remind the user that they're two days away from the weekly milestone, where they will measure their current weight compared to their starting weight. Explain to the user that now they've got their primary daily habit down - weighing themselves - Dr. Bill thinks it's important to introduce the other main habit that leads to weight loss. Ask the user if they can guess what it is?",
      "checked": false
    },
    {
      "text": "Respond to the user's guess by relating it to the actual answer: movement and exercise. Explain to the user we want to start thinking of food like fuel, and their body like an engine. You want to make sure you have enough fuel in your tank, but you don't want to overfill it! Ask the user if they're currently exercising or getting any movement in daily?",
      "checked": false
    },
    {
      "text": "Remind the user WHY they are in this program and hearing all these weight loss secrets, and explain how daily exercise and movement can contribute to reaching that goal. Remind the user adding in some simple exercises is going to help them reach their goal faster. Tell the user no matter what their current exercise regime is, Dr. Bill has five secrets to stay consistent. Ask the user if they can guess what the keys to motivating themselves to exercise or get some light movement in daily?",
      "checked": false
    },
    {
      "text": "React to the user's reply by encouraging them to listen to a few tips from Dr. Bill. Tell them the first one is to think of daily movement and exercise like hygiene. Ask the user if they shower or bathe daily?",
      "checked": false
    },
    {
      "text": "React by saying something like 'of course you do, we all do!', and tell them that's right, try to think about your exercise like you think about your morning shower: refreshing and necessary. Then, tell them just like a shower, building an exercise habit can start small. Ask them how long they stay in the shower?",
      "checked": false
    },
    {
      "text": "React by saying something like, 'that's right, all good habits start small!'. Remind the user the most important part of being consistent with getting some movement or exercise in is to start small. You don't need a gym or to do any sophisticated exercises. Even walking to get the mail counts! Then, ask the user if they have a cell phone?",
      "checked": false
    },
    {
      "text": "React with a humorous comment by saying something like 'of course you have a cell phone!'. Remind the users that every smartphone now includes a step-tracker function, what Dr. Bill would call a 'pedometer' when he started his career. Setting a daily goal for steps can really help ensure you're moving daily. You should aim for at least 7,500 steps each day. Then, ask the user what time they usually eat dinner?",
      "checked": false
    },
    {
      "text": "React by suggesting the user makes an after-dinner walk a habit to end the day. Dr. Bill calls this 'European style'. It helps with digestion and burns calories, and if it's too cold out, saving your chores and any house cleaning for the end of the day can be a good substitute.",
      "checked": false
    },
    {
      "text": "Say something like Movement is movement, no matter how small, and it’s going to get you closer to that goal.",
      "checked": false
    },
    {
      "text": "Congratulate the user on all their progress, and then hang up! No call should last more than 2 minutes.",
      "checked": false
    }
  ]);

  const handleCheck = (index) => {
    if (!allowCheck) return;
    const updatedItems = [...items];
    updatedItems[index].checked = !updatedItems[index].checked;
    setItems(updatedItems);
  };

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Instructions
      </Typography>
      <Typography variant="body1" paragraph>
        Please cover these items during the call:
      </Typography>

      <List>
        {items.map((item, idx) => (
          <ListItem
            key={idx}
            onClick={() => handleCheck(idx)}
            sx={{ cursor: allowCheck ? 'pointer' : 'default' }}
          >
            <ListItemIcon>
              {allowCheck ? (
                <Checkbox
                  edge="start"
                  checked={item.checked}
                  tabIndex={-1}
                  disableRipple
                />
              ) : (
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 2 }}>
                  •
                </Typography>
              )}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                sx: {
                  textDecoration: item.checked && allowCheck ? 'line-through' : 'none',
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default QuadrantInstructions;
