import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import ScheduleView from './ScheduleView';
import CallQuadrants from './CallQuadrants';
import DataLabelingPage from './DataLabelingPage';
import DataMetricsDashboard from './DataMetricsDashboard';
import ManagementPage from './ManagementPage';

function BonusDataPortal() {
    // ----------------------------------------------------------------
    // State from localStorage (with sane defaults)
    // ----------------------------------------------------------------
    const storedRole = localStorage.getItem('debugUserRole') || 'data labeler';
    const storedTab = localStorage.getItem('debugUserTab') || 'schedules';

    const [role, setRole] = useState(storedRole);
    const [selectedTab, setSelectedTab] = useState(storedTab);
    const [currentTime, setCurrentTime] = useState(new Date());

    // ----------------------------------------------------------------
    // Keep role and selectedTab in localStorage
    // ----------------------------------------------------------------
    useEffect(() => {
      localStorage.setItem('debugUserRole', role);
    }, [role]);

    useEffect(() => {
      localStorage.setItem('debugUserTab', selectedTab);
    }, [selectedTab]);

    // ----------------------------------------------------------------
    // Update clock every second
    // ----------------------------------------------------------------
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
      return () => clearInterval(timer);
    }, []);

    // ----------------------------------------------------------------
    // Handlers
    // ----------------------------------------------------------------
    const handleRoleChange = (event) => {
      // Reset tab to 'schedules' when role changes
      setRole(event.target.value);
      setSelectedTab('schedules');
    };

    const handleTabChange = (tabName) => {
      setSelectedTab(tabName);
    };

    const handleLogout = () => {
      // Replace with your logout logic
      alert('Logout (placeholder)');
    };

    // ----------------------------------------------------------------
    // Format time with seconds
    // ----------------------------------------------------------------
    const formatTime = (dateObj) => {
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    };

    // ----------------------------------------------------------------
    // Conditional main content
    // ----------------------------------------------------------------
    const renderMainContent = () => {
      if (role === 'data labeler') {
        if (selectedTab === 'schedules') {
          return <ScheduleView />;
        }
        // else selectedTab === 'call'
        return <CallQuadrants />;
      }

      if (role === 'data curator') {
        if (selectedTab === 'schedules') {
          return <ScheduleView />;
        }
        if (selectedTab === 'call') {
          return <CallQuadrants />;
        }
        // else selectedTab === 'ratings'
        return <DataLabelingPage />;
      }

      if (role === 'admin') {
        if (selectedTab === 'schedules') {
          return <ScheduleView />;
        }
        if (selectedTab === 'call') {
          return <CallQuadrants />;
        }
        if (selectedTab === 'ratings') {
          return <DataLabelingPage />;
        }
        if (selectedTab === 'metrics') {
          return <DataMetricsDashboard />;
        }
        // else selectedTab === 'management'
        return <ManagementPage />;
      }

      // Default fallback
      return <DataMetricsDashboard />;
    };

    // ----------------------------------------------------------------
    // Build the dynamic menu: each role's items
    // ----------------------------------------------------------------
    // Data Labeler => 'Schedules','Call'
    // Data Curator => 'Schedules','Call','Ratings'
    // Admin => 'Schedules','Call','Ratings','Metrics','Manage Roles/Users'
    let menuItems = [];
    if (role === 'data labeler') {
      menuItems = [
        { label: 'Schedules', value: 'schedules' },
        { label: 'Call', value: 'call' },
      ];
    } else if (role === 'data curator') {
      menuItems = [
        { label: 'Schedules', value: 'schedules' },
        { label: 'Call', value: 'call' },
        { label: 'Ratings', value: 'ratings' },
      ];
    } else if (role === 'admin') {
      menuItems = [
        { label: 'Schedules', value: 'schedules' },
        { label: 'Call', value: 'call' },
        { label: 'Ratings', value: 'ratings' },
        { label: 'Metrics', value: 'metrics' },
        { label: 'Manage Roles/Users', value: 'management' },
      ];
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <AppBar position="static">
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Left side: logo, "Welcome Doug", Role selection & time with seconds */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <Box
                component="img"
                src="/logo.png"
                alt="Logo"
                sx={{ height: 40, width: 80, marginRight: 2 }}
                  />
                  <Typography variant="h6">Welcome Doug</Typography>

                  <FormControl variant="standard">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  value={role}
                  onChange={handleRoleChange}
                  label="Role"
                  sx={{ color: '#fff' }}
                >
                  <MenuItem value="data labeler">Data Labeler</MenuItem>
                  <MenuItem value="data curator">Data Curator</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
                  </FormControl>

                  <Typography variant="h6">
                {formatTime(currentTime)}
                  </Typography>
                </Box>

                {/* Right side: dynamic menu & logout */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item) => (
                <Button
                  key={item.value}
                  variant="text"
                  color="inherit"
                  onClick={() => handleTabChange(item.value)}
                  sx={{
                    textDecoration:
                      selectedTab === item.value ? 'underline' : 'none',
                  }}
                >
                  {item.label}
                </Button>
              ))}

              <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Main Content Area */}
        <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
          {renderMainContent()}
        </Box>
      </Box>
    );
  }

  export default BonusDataPortal;
